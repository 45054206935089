import React from "react";
import styled from "styled-components";

const TableWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 10px; /* Zaokrąglona górna krawędź */
  border-top-right-radius: 10px; /* Zaokrąglona górna krawędź */
  border-bottom-left-radius: 10px; /* Zaokrąglona dolna krawędź */
  border-bottom-right-radius: 10px; /* Zaokrąglona dolna krawędź */
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: #f1f3f7;
`;

const TableBody = styled.tbody`
  max-height: 300px; /* Dostosuj wysokość do swoich potrzeb */
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    border: 1px solid #f1f3f7;
    background: #fafbfc;
  }
`;

const TableCell = styled.td`
  min-width: 60px;

  padding: 8px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #404040;

  /* Text Smal 10 */
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TableHeadCell = styled.th`
  padding: 8px;
  background-color: #f1f3f7;
  text-align: left;

  color: #05275d;

  /* Text small Bold */
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const DashbaordTable = ({ headers = [], data = [] }) => {
  return (
    <TableWrapper>
      <Table>
        <TableHeader>
          <TableRow>
            {headers.map((header, index) => (
              <TableHeadCell key={index}>{header}</TableHeadCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <TableCell key={cellIndex}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default DashbaordTable;
