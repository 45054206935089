import CustomTable from "@/common/components/custom/CustomTable";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import AdminWordLessonTypeEdit from "./components/AdminDailyConversationEdit";
import { getWordLessonTypeConfig } from "@/api/query/word-lesson-type-config";
import Loading from "@/common/components/Loading";
import { getDailyConversationConfigs } from "@/api/query/dail-config";
import DataTable from "@/common/components/custom/DataTable";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminDailyConversation = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState();

  const HEADERS = [
    {
      header: "no.",
      accessorKey: "no",
      size: 20,
    },
    {
      header: "Name",
      accessorKey: "name",
      size: 20,
    },
    {
      header: "Active",
      accessorKey: "active",
      size: 20,
    },
    {
      header: "Created by",
      accessorKey: "created_by",
      size: 20,
    },

    {
      header: "Updated by",
      accessorKey: "updated_by",
      size: 20,
    },
    {
      header: "Actions",
      accessorKey: "actions",
      size: 20,
      cell: (info) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const findWordLessonsConfigMutation = useMutation({
    mutationFn: () => getDailyConversationConfigs(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          name: d.name,
          active: d?.isDefault ? "tak" : "nie",
          created_by: d.created_by?.email,
          updated_by: d.updated_by?.email,
          actions: d,
        }))
      );
      setData(data);
    },
  });

  useEffect(() => {
    if (!edit) {
      findWordLessonsConfigMutation.mutate();
    }
  }, [edit]);

  return (
    <>
      {findWordLessonsConfigMutation.isLoading && <Loading />}
      <Wrapper>
        {edit && <AdminWordLessonTypeEdit edit={edit} setEdit={setEdit} />}
        <TopContent>
          <ActionWrapper>
            <CustomAddButton onClick={() => setEdit({})} />
          </ActionWrapper>
        </TopContent>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[5, 10]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminDailyConversation;
