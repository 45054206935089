import CustomTable from "@/common/components/custom/CustomTable";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import Loading from "@/common/components/Loading";
import { getAiPersons } from "@/api/query/ai-person";
import AdminCustomerProfileEdit from "./components/AdminCustomerProfileEdit";
import { getCustomerProfiles } from "@/api/query/customer-profile";
import DataTable from "@/common/components/custom/DataTable";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminCustomerProfile = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState();

  const HEADERS = [
    {
      header: "no.",
      accessorKey: "no",
      size: 20,
    },
    {
      header: "Name",
      accessorKey: "name",
      size: 20,
    },
    {
      header: "Icon",
      accessorKey: "icon",
      size: 20,
      cell: (info) => (
        <div
          style={{
            width: "50px",
            height: "50px",
            overflow: "hidden",
            borderRadius: "50%",
          }}
        >
          <img
            alt=""
            src={info.getValue()}
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      ),
    },
    {
      header: "Actions",
      accessorKey: "actions",
      size: 20,
      cell: (info) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const findWordLessonsConfigMutation = useMutation({
    mutationFn: () => getCustomerProfiles(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          name: d.name,
          icon: d.icon?.base64,
          actions: d,
        }))
      );
      setData(data);
    },
  });

  useEffect(() => {
    if (!edit) {
      findWordLessonsConfigMutation.mutate();
    }
  }, [edit]);

  return (
    <>
      {findWordLessonsConfigMutation.isLoading && <Loading />}
      <Wrapper>
        {edit && <AdminCustomerProfileEdit edit={edit} setEdit={setEdit} />}
        <TopContent>
          <ActionWrapper>
            <CustomAddButton onClick={() => setEdit({})} />
          </ActionWrapper>
        </TopContent>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminCustomerProfile;
