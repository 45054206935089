import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Color } from "../colors/colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DEFAULT_LINE_OPTIONS = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        font: {
          color: Color.darkBlue,
        },
        color: Color.darkBlue,
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      beginAtZero: true,
    },
  },
};

const CustomLine = ({ data, options }) => {
  return <Line options={{ ...DEFAULT_LINE_OPTIONS, ...options }} data={data} />;
};

export default CustomLine;
