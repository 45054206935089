import React from "react";
import styled from "styled-components";
import CSV from "../CSV";

const TableWrapper = styled.div`
  width: 100%;
  border-top-left-radius: 10px; /* Zaokrąglona górna krawędź */
  border-top-right-radius: 10px; /* Zaokrąglona górna krawędź */
  border-bottom-left-radius: 10px; /* Zaokrąglona dolna krawędź */
  border-bottom-right-radius: 10px; /* Zaokrąglona dolna krawędź */
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: #f0f0f0;
`;

const TableBody = styled.tbody`
  max-height: 300px; /* Dostosuj wysokość do swoich potrzeb */
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  padding: 8px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableHeadCell = styled.th`
  padding: 8px;
  background-color: lightgrey;
  text-align: left;
`;

const NewCustomTable = ({ headers = [], data = [], csvData, csvHeaders }) => {
  return (
    <TableWrapper>
      <Table>
        <TableHeader>
          {csvData && (
            <tr>
              <th colSpan={headers.length}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    margin: "5px 10px",
                  }}
                >
                  <CSV
                    header={csvHeaders || headers}
                    data={csvData}
                    filename={`download.csv`}
                  />
                </div>
              </th>
            </tr>
          )}
          <TableRow>
            {headers.map((header, index) => (
              <TableHeadCell key={index}>{header}</TableHeadCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <TableCell key={cellIndex}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default NewCustomTable;
