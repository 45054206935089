import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import FlashcardEdit from "./components/FlashCardEdit";
import moment from "moment";
import { getImageModes } from "@/api/query/image-mode";
import { formatDate } from "../mail/AdminMail";
import DataTable from "@/common/components/custom/DataTable";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminImageMode = () => {
  const [editFlashcard, setEditFlashcard] = useState();
  const [tableData, setTableData] = useState([]);

  const HEADERS = [
    {
      header: "no.",
      accessorKey: "no",
      size: 20,
    },
    {
      header: "Creation Date",
      accessorKey: "created_at",
      size: 20,
      cell: (info) => formatDate(info.getValue()),
    },
    {
      header: "Description",
      accessorKey: "description",
      size: 20,
    },
    {
      header: "Image",
      accessorKey: "image",
      size: 20,
      cell: (info) => (
        <div style={{ width: "50px", overflow: "hidden" }}>
          <img
            src={info.getValue()}
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      ),
    },
  ];

  const findFlashCardsMutation = useMutation({
    mutationFn: () => getImageModes(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          ?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .map((d, i) => ({
            no: i + 1,
            created_at: d.created_at,
            description: d.description,
            image: d.image.base64,
          }))
      );
    },
  });

  useEffect(() => {
    if (!editFlashcard) {
      findFlashCardsMutation.mutate();
    }
  }, [editFlashcard]);

  return (
    <>
      {findFlashCardsMutation.isLoading && <Loading />}
      <Wrapper>
        {editFlashcard && (
          <FlashcardEdit edit={editFlashcard} setEdit={setEditFlashcard} />
        )}
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[3, 5, 10]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminImageMode;
