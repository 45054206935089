import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Link } from "react-router-dom";
import { getImageModeStats } from "@/api/query/image-mode";

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = ["No.", "Date", "User", "Status", "Task", "Answer", "Actions"];
const HEADERS_CSV = ["No.", "Date", "User", "Status", "Task", "Answer"];

export const formatDate = (d) => {
  if (!d) {
    return "------";
  }

  return moment(d).format("DD/MM/YYYY HH:mm");
};

const AdminFotoModeStats = () => {
  const [tableData, setTableData] = useState();
  const [tableDataCSV, setTableDataCSV] = useState();

  const findMailMutation = useMutation({
    mutationFn: () => getImageModeStats(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => [
          <div>{i + 1}.</div>,
          <div>{formatDate(d.created_at)}</div>,
          <div>{d.customer.email}</div>,
          <div>{d.status}</div>,
          <div>{d.task}</div>,
          <div>{d.answer || "--------"}</div>,
          <Link to={`/admin/customer/stats/${d.customer?._id}`}>
            <FontAwesomeIcon icon={faEye} />
          </Link>,
        ])
      );
      setTableDataCSV(() =>
        data.map((d, i) => [
          i + 1,
          formatDate(d.created_at),
          d.customer.email,
          d.status,
          d.task,
          d.answer || "--------",
        ])
      );
    },
  });

  useEffect(() => {
    findMailMutation.mutate();
  }, []);

  return (
    <>
      {findMailMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <CustomTable
            headers={HEADERS}
            data={tableData}
            csvHeaders={HEADERS_CSV}
            csvData={tableDataCSV}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminFotoModeStats;
