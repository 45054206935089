import CustomTable from "@/common/components/custom/CustomTable";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import AdminCategoryEdit from "./components/AdminCategoryEdit";
import { useMutation } from "react-query";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { deleteCategory, findCategories } from "@/api/query/category";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import DataTable from "@/common/components/custom/DataTable";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminCategory = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const deleteCategoryMutation = useMutation({
    mutationFn: (payload) => deleteCategory(payload),
    onSuccess: ({ data }) => {
      findLessonsMutation.mutate();
    },
  });

  const handleDeleteCategory = (d) => {
    const payload = {};
    payload._id = d._id;

    deleteCategoryMutation.mutate(payload);
  };

  const [edit, setEdit] = useState();

  const HEADERS = [
    {
      header: "no.",
      accessorKey: "no",
      size: 20,
    },
    {
      header: "Name",
      accessorKey: "name",
      size: 20,
    },
    {
      header: "Value",
      accessorKey: "value",
      size: 20,
    },
    {
      header: "Lessons",
      accessorKey: "lessons",
      size: 20,
    },
    {
      header: "Image",
      accessorKey: "image",
      size: 20,
      cell: (info) => (
        <div
          style={{
            width: "50px",
            height: "50px",
            overflow: "hidden",
            borderRadius: "50%",
          }}
        >
          <img
            alt=""
            src={info.getValue()}
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      ),
    },
    {
      header: "Is Block",
      accessorKey: "is_block",
      size: 20,
    },
    {
      header: "Actions",
      accessorKey: "actions",
      size: 20,
      cell: (info) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
          <CustomDeleteButton
            onClick={(e) => handleDeleteCategory(info.getValue())}
          />
        </div>
      ),
    },
  ];

  const findLessonsMutation = useMutation({
    mutationFn: () => findCategories(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          name: d.name,
          value: d.value,
          lessons: d.lessons?.length,
          image: d.image?.base64,
          is_block: d.isBlock ? "true" : "false",
          actions: d,
        }))
      );
      setData(data);
    },
  });

  useEffect(() => {
    if (!edit) {
      findLessonsMutation.mutate();
    }
  }, [edit]);

  return (
    <Wrapper>
      {edit && <AdminCategoryEdit edit={edit} setEdit={setEdit} />}
      <TopContent>
        <ActionWrapper>
          <CustomAddButton onClick={() => setEdit({})} />
        </ActionWrapper>
      </TopContent>
      <TableWrapper>
        <DataTable columns={HEADERS} data={tableData} rows={[5, 10]} />
      </TableWrapper>
    </Wrapper>
  );
};

export default AdminCategory;
