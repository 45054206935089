import React from "react";
import { styled } from "styled-components";
import { UserStats } from "./components/usrsStats/UserStats";
import UsageStats from "./components/usageStats/UsageStats";
import MailStats from "./components/mailStats/MailStats";
import FeatureUsage from "./components/featureUsage/FeatureUsage";
import BuyRoad from "./components/buyRoad/BuyRoad";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  gap: 20px;
`;

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const ChartWrapper = styled.div`
  width: 600px;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminPanel = () => {
  return (
    <>
      <Wrapper>
        <ChartWrapper>
          <UserStats />
        </ChartWrapper>
        <ChartWrapper>
          <UsageStats />
        </ChartWrapper>
        <ChartWrapper>
          <FeatureUsage />
        </ChartWrapper>
        <ChartWrapper>
          <BuyRoad />
        </ChartWrapper>
        {/* <MailStats /> */}
      </Wrapper>
    </>
  );
};

export default AdminPanel;
